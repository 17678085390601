import { Text, Space, Icon, theme } from 'verak-ui'
import { useLocation } from 'react-router'
import Swal from 'sweetalert2'

import notes from '../assets/notes.svg'

const Toast = Swal.mixin({
	toast: true,
	position: 'bottom-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: toast => {
		toast.addEventListener('mouseenter', Swal.stopTimer)
		toast.addEventListener('mouseleave', Swal.resumeTimer)
	},
})

// ref: https://stackoverflow.com/questions/50795042/create-a-copy-button-without-an-input-text-box/50795833
const copyValue = (event, value, text) => {
	event.stopPropagation()

	const textArea = document.createElement('textarea')
	textArea.value = value
	document.body.appendChild(textArea)
	textArea.select()
	document.execCommand('copy')
	document.body.removeChild(textArea)

	Toast.fire({
		icon: 'success',
		title: `${text} copied to clipboard`,
	})
}

const Success = () => {
	const location = useLocation()
	const UID = location?.state?.uid

	const ctas = UID
		? [
				{
					text: 'Fill Form',
					subText: 'Continue with lead submission',
					path: '/',
					separateTab: false,
				},
				{
					text: 'Open SQS',
					subText: 'Generate RFQ for leads',
					path: `https://platform-f.verak.in/start-draft?uid=${UID}`,
					separateTab: true,
				},
		  ]
		: [
				{
					text: 'Fill Form',
					subText: 'Continue with lead submission',
					path: '/',
					separateTab: false,
				},
		  ]

	return (
		<div style={{ width: 'min(350px, 100%)', margin: '1rem auto' }}>
			<Space y={1.75} />
			<p style={{ opacity: 0.6 }}>Form submitted</p>
			<p style={{ fontSize: '24px', fontWeight: 700, color: theme.colors.green }}>
				SUCCESSFULLY 🎉
			</p>

			{UID ? (
				<>
					<Space y={1.75} />
					<p style={{ fontSize: '12px', opacity: 0.6 }}>UID</p>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<p style={{ fontSize: '16px', color: theme.colors.black }}>{UID}</p>
						<Space x={1} />
						<ClipboardCTA copyValue={copyValue} label={UID} toastText="UID" />
					</div>
				</>
			) : null}

			<Space y={1.75} />
			<Text style={{ opacity: 0.4 }}>SELECT TO PROCEED</Text>
			{ctas.map(el => (
				<CTAs
					text={el.text}
					subText={el.subText}
					path={el.path}
					separateTab={el.separateTab}
					key={el.text}
				/>
			))}
		</div>
	)
}

export default Success

const CTAs = ({ text, subText, separateTab, path }) => {
	return (
		<a href={path} target={separateTab ? '_blank' : ''} rel="noreferrer">
			<div className="full-flex-container" style={ctaContainerStyle}>
				<div className="flex-container">
					<img src={notes} alt="notes" />
					<Space x={1} />
					<div>
						<p
							style={{
								...ctaTextStyle,
								fontSize: '16px',
								fontWeight: 700,
							}}
						>
							{text}
						</p>
						<p style={ctaTextStyle}>{subText}</p>
					</div>
				</div>
				<Icon name="rightArrow" color={theme.colors.black} />
			</div>
		</a>
	)
}

const ClipboardCTA = ({ copyValue, label, toastText }) => {
	return (
		<div
			onClick={e => {
				copyValue(e, label, toastText)
			}}
			style={{ cursor: 'pointer' }}
		>
			<Icon name="copyToClipboard" />
		</div>
	)
}

const ctaContainerStyle = {
	padding: '14px',
	cursor: 'pointer',
	border: '1px solid rgba(0, 0, 0, 0.2)',
	borderRadius: '4px',
	marginTop: '1rem',
	width: 'min(390px, 100%)',
}

const ctaTextStyle = { fontSize: '12px', letterSpacing: '0.01em', color: '#4C4C4C' }
