import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Input, InputGroup, PhoneNoInput, Space, Text, theme, Select, Button } from 'verak-ui'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router'

import { useFetch } from '../api/apihooks'
import { getTypeOfFacility } from '../utils/getTypeOfFacility'
import { getStockPlaceholder } from '../utils/getStockPlaceholder'
import { convertToNumber } from '../utils/convertToNumber'

const LeadInfo = () => {
	const history = useHistory()
	const { user } = useAuth0()
	let executiveEmail = user?.email

	// Customer details
	const [name, setName] = useState('')
	const [number, setNumber] = useState('')
	const [companyName, setCompanyName] = useState('')

	// Facility details
	const [facility, setFacility] = useState(getTypeOfFacility(executiveEmail))
	const [business, setBusiness] = useState('')
	const [isOtherFacility, setIsOtherFacility] = useState(false)
	const [otherFacility, setOtherFacility] = useState('')
	const [isOtherBusiness, setIsOtherBusiness] = useState(false)
	const [otherBusiness, setOtherBusiness] = useState('')
	const [facilityUITitle, setFacilityUITitle] = useState('')

	// Materials details
	const [wall, setWall] = useState('')
	const [isOtherWall, setIsOtherWall] = useState(false)
	const [otherWall, setOtherWall] = useState('')
	const [roof, setRoof] = useState('')
	const [isOtherRoof, setIsOtherRoof] = useState(false)
	const [otherRoof, setOtherRoof] = useState('')

	// Stock details
	const [goodsSold, setGoodsSold] = useState('')
	const [rawMaterials, setRawMaterials] = useState('')
	const [finishedGoods, setFinishedGoods] = useState('')

	// Sum Insured details
	const [ownership, setOwnership] = useState('')
	const [stockValue, setStockValue] = useState('')
	const [structureValue, setStructureValue] = useState('')
	const [plantMachinery, setMachineValue] = useState('')
	const [officeEquipment, setOfficeEquipmentValue] = useState('')
	const [furnitureFixture, setFurnitureFixtureValue] = useState('')
	const [electronics, setElectronicsValue] = useState('')

	// Address and Structure details
	const [pincode, setPincode] = useState('')
	const [address, setAddress] = useState('')
	const [floor, setFloor] = useState('')
	const [city, setCity] = useState('')
	const [state, setState] = useState('')

	const [disableSubmitBtn, setDisable] = useState(true)
	const [error, setError] = useState('')

	const { uploadReviewedData, hideNOBFor, getFacilityData } = useFetch()
	const hideNOBForFacilities = hideNOBFor()
	const facilityData = getFacilityData()

	// Customer phone no
	const handlePhoneChange = e => {
		const phoneNumber = e.target.value
		if ((Number(phoneNumber) || phoneNumber === '') && phoneNumber.length <= 10) {
			setNumber(phoneNumber)
		}
	}
	let validPhoneNumber = true
	if (number.length > 0) {
		validPhoneNumber = !isNaN(number) && number.length === 10
	}

	// Type of facility and Nature of Business
	const handleFacility = e => {
		setIsOtherFacility(e === 'Other')
		setFacility(e)
	}
	const handleBusiness = e => {
		setIsOtherBusiness(e === 'Other')
		setBusiness(e)
	}
	useEffect(() => {
		setFacilityUITitle(facility)
		if (facility === 'Other' || facility === 'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen') {
			setFacilityUITitle('facility')
		} else if (facility?.startsWith('Motor')) {
			setFacilityUITitle(facility?.split(' ')[2]) // showroom, garage
		}
	}, [facility])

	// Sum Insured
	const handleStockValue = e => {
		const numValue = convertToNumber(e)
		setStockValue(numValue)
	}
	const handleStructureValue = e => {
		const numValue = convertToNumber(e)
		setStructureValue(numValue)
	}
	const handleMachineValue = e => {
		const numValue = convertToNumber(e)
		setMachineValue(numValue)
	}
	const handleOfficeEquipmentValue = e => {
		const numValue = convertToNumber(e)
		setOfficeEquipmentValue(numValue)
	}
	const handleFurnitureValue = e => {
		const numValue = convertToNumber(e)
		setFurnitureFixtureValue(numValue)
	}
	const handleElectricalValue = e => {
		const numValue = convertToNumber(e)
		setElectronicsValue(numValue)
	}

	// Materials
	const handleWalls = e => {
		setIsOtherWall(e === 'Other')
		setWall(e)
	}
	const handleRoof = e => {
		setIsOtherRoof(e === 'Other')
		setRoof(e)
	}

	// Address
	const handlePincode = e => {
		if ((Number(e) || e === '') && e.length <= 6) {
			setPincode(e)
		}
	}
	const fetchCityAndStateVals = async () => {
		if (pincode.length === 6) {
			const response = await fetch(`https://api.postalpincode.in/pincode/${pincode}`)
			const data = await response.json()
			const locations = data?.[0]?.PostOffice
			if (locations?.length) {
				setCity(locations?.[0]?.District)
				setState(locations?.[0]?.State)
			} else {
				setCity('')
				setState('')
			}
		}
	}
	useEffect(() => {
		fetchCityAndStateVals()
		// eslint-disable-next-line
	}, [pincode])

	useEffect(() => {
		let facilityType = facility
		let businessType = business
		let finalWallMaterial = wall
		let finalRoofMaterial = roof

		if (isOtherFacility) {
			facilityType = otherFacility
		}
		if (isOtherBusiness) {
			businessType = otherBusiness
		}
		if (isOtherWall) {
			finalWallMaterial = otherWall
		}
		if (isOtherRoof) {
			finalRoofMaterial = otherRoof
		}

		if (
			name &&
			companyName &&
			validPhoneNumber &&
			facilityType &&
			finalWallMaterial &&
			finalRoofMaterial &&
			ownership &&
			(stockValue ||
				structureValue ||
				furnitureFixture ||
				electronics ||
				plantMachinery ||
				officeEquipment) &&
			pincode &&
			pincode.length === 6 &&
			address &&
			floor &&
			city &&
			state
		) {
			if (hideNOBForFacilities?.includes(facility)) {
				// case of: Motor vehicle showroom, Motor vehicle garage ....
				setDisable(false)
			} else if (facility !== 'Other' && businessType) {
				// case of: Shop, Godown/Warehouse ....
				setDisable(false)
			} else {
				setDisable(true)
			}
			// eslint-disable-next-line
		} else {
			setDisable(true)
		}
		// eslint-disable-next-line
	}, [
		name,
		number,
		companyName,
		facility,
		business,
		otherFacility,
		otherBusiness,
		wall,
		roof,
		otherWall,
		otherRoof,
		ownership,
		stockValue,
		structureValue,
		plantMachinery,
		furnitureFixture,
		officeEquipment,
		electronics,
		pincode,
		address,
		floor,
		city,
		state,
	])

	const handleSubmit = async () => {
		let facilityType = facility
		let businessType = business
		let finalWallMaterial = wall
		let finalRoofMaterial = roof

		if (isOtherFacility) {
			facilityType = otherFacility
		}
		if (isOtherBusiness) {
			businessType = otherBusiness
		}
		if (isOtherWall) {
			finalWallMaterial = otherWall
		}
		if (isOtherRoof) {
			finalRoofMaterial = otherRoof
		}

		const payload = {
			CustomerName: name,
			CompanyName: companyName,
			Phone: `+91${number}`,
			FacilityType: facilityType,
			BusinessType: businessType,
			WallMaterial: finalWallMaterial,
			RoofMaterial: finalRoofMaterial,
			NatureOfStock: goodsSold,
			RawMaterial: rawMaterials,
			FinishedGoods: finishedGoods,
			Ownership: ownership === 'Owned' ? 'SOLE' : 'RENTED',
			PIN: pincode,
			Address: address,
			Floor: floor,
			City: city,
			State: state,
			SumInsuredBreakdown: {
				Stock: stockValue,
				PlantMachinery: plantMachinery,
				Property: structureValue,
				OfficeEquipment: officeEquipment,
				FurnitureFixture: furnitureFixture,
				Electronics: electronics,
			},
		}
		setDisable(true)
		const result = await uploadReviewedData(payload)
		if (result?.error) {
			setError(result?.error)
			setDisable(false)
		} else {
			history.push({
				pathname: '/success',
				state: {
					uid: result?.id,
				},
			})
		}
	}

	return (
		<Container>
			<CustomerDetails
				setName={setName}
				number={number}
				handlePhoneChange={handlePhoneChange}
				validPhoneNumber={validPhoneNumber}
				setCompanyName={setCompanyName}
			/>

			<FacilityDetails
				hideNOBForFacilities={hideNOBForFacilities}
				facility={facility}
				handleFacility={handleFacility}
				isOtherFacility={isOtherFacility}
				setOtherFacility={setOtherFacility}
				setBusiness={setBusiness}
				business={business}
				handleBusiness={handleBusiness}
				isOtherBusiness={isOtherBusiness}
				setOtherBusiness={setOtherBusiness}
				facilityData={facilityData}
				facilityUITitle={facilityUITitle}
			/>

			<MaterialsDetails
				wall={wall}
				roof={roof}
				handleWalls={handleWalls}
				handleRoof={handleRoof}
				isOtherWall={isOtherWall}
				isOtherRoof={isOtherRoof}
				setOtherWall={setOtherWall}
				setOtherRoof={setOtherRoof}
			/>

			{facility ? (
				<>
					<Space y={1} />
					<StockDetails
						facility={facility}
						business={business}
						setGoodsSold={setGoodsSold}
						setRawMaterials={setRawMaterials}
						setFinishedGoods={setFinishedGoods}
						goodsSold={goodsSold}
						rawMaterials={rawMaterials}
						finishedGoods={finishedGoods}
						facilityUITitle={facilityUITitle}
					/>
					<Space y={1} />
					<SumInsuredDetails
						facilityUITitle={facilityUITitle}
						ownership={ownership}
						setOwnership={setOwnership}
						goodsSold={goodsSold}
						rawMaterials={rawMaterials}
						finishedGoods={finishedGoods}
						facilityData={facilityData}
						facility={facility}
						stockValue={stockValue}
						structureValue={structureValue}
						plantMachinery={plantMachinery}
						officeEquipment={officeEquipment}
						furnitureFixture={furnitureFixture}
						electronics={electronics}
						handleStockValue={handleStockValue}
						handleStructureValue={handleStructureValue}
						handleMachineValue={handleMachineValue}
						handleOfficeEquipmentValue={handleOfficeEquipmentValue}
						handleFurnitureValue={handleFurnitureValue}
						handleElectricalValue={handleElectricalValue}
					/>
				</>
			) : null}

			<AddressAndStructureDetails
				pincode={pincode}
				handlePincode={handlePincode}
				setAddress={setAddress}
				floor={floor}
				setFloor={setFloor}
				city={city}
				setCity={setCity}
				state={state}
				setState={setState}
			/>

			<Space y={2} />
			<Button
				label="Submit"
				icon="rightArrow"
				iconAlign="right"
				disabled={disableSubmitBtn}
				onClick={handleSubmit}
			/>
			<Space y={1} />
			{error ? <Text color={theme.colors.red}>Error: {error}</Text> : null}
		</Container>
	)
}

export default LeadInfo

const GetInputForFacility = ({ fn, label, value }) => {
	return (
		<>
			<Space y={1} />
			<Input
				label={label}
				placeholder="Enter here"
				onChange={e => fn(e.target.value)}
				value={value}
				autoComma
				rupee
				style={{ width: '320px' }}
			/>
		</>
	)
}
const SumInsuredDetails = ({
	facilityUITitle,
	ownership,
	setOwnership,
	goodsSold,
	rawMaterials,
	finishedGoods,
	facilityData,
	facility,
	stockValue,
	structureValue,
	plantMachinery,
	officeEquipment,
	furnitureFixture,
	electronics,
	handleStockValue,
	handleStructureValue,
	handleMachineValue,
	handleOfficeEquipmentValue,
	handleFurnitureValue,
	handleElectricalValue,
}) => {
	const { getOwnershipValues } = useFetch()
	const ownershipValues = getOwnershipValues()

	let stockQues = null
	let structureQues = null
	let otherSIQues = null
	if (goodsSold || rawMaterials || finishedGoods) {
		stockQues = (
			<>
				<Space y={1} />
				<Input
					label={facilityData[facility]?.stockQues}
					placeholder="Enter here"
					onChange={e => handleStockValue(e.target.value)}
					value={stockValue}
					autoComma
					rupee
					style={{ width: '320px' }}
				/>
			</>
		)
	}

	if (ownership === 'Owned') {
		structureQues = (
			<>
				<Space y={1} />
				<Input
					label="Cost of structure (e.g. ₹10,00,000)"
					placeholder="Enter here"
					onChange={e => handleStructureValue(e.target.value)}
					value={structureValue}
					autoComma
					rupee
					style={{ width: '320px' }}
				/>
			</>
		)
	}

	if (facility === 'Godown/Warehouse' || facility === 'Factory/Manufacturer') {
		otherSIQues = (
			<GetInputForFacility
				fn={handleMachineValue}
				label="Value of machines (e.g. ₹10,00,000)"
				value={plantMachinery}
			/>
		)
	} else if (facility === 'Office') {
		otherSIQues = (
			<>
				<GetInputForFacility
					fn={handleOfficeEquipmentValue}
					label="Cost of IT & other office equipment"
					value={officeEquipment}
				/>
				<GetInputForFacility
					fn={handleFurnitureValue}
					label="Cost of furniture in office (e.g. Tables/Desks)"
					value={furnitureFixture}
				/>
			</>
		)
	} else if (facility === 'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen') {
		otherSIQues = (
			<GetInputForFacility
				fn={handleElectricalValue}
				label="Cost of electrical equipments (e.g. Fridges/Ovens)"
				value={electronics}
			/>
		)
	} else if (facility === 'Other') {
		otherSIQues = (
			<GetInputForFacility
				fn={handleFurnitureValue}
				label="Cost of furniture (e.g. Tables/Desks)"
				value={furnitureFixture}
			/>
		)
	}

	useEffect(() => {
		handleStockValue('')
		handleStructureValue('')
		handleElectricalValue('')
		handleFurnitureValue('')
		handleMachineValue('')
		handleOfficeEquipmentValue('')
		// eslint-disable-next-line
	}, [facility])

	return (
		<>
			<Space y={1} />
			<p>Sum Insured details:</p>
			<Space y={1} />
			<Text style={textStyle}>
				Is your {facilityUITitle} owned or rented?
				<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
			</Text>
			<CustomSelect placeholder="Select" value={ownership} onChange={e => setOwnership(e)}>
				{ownershipValues.map(x => {
					return (
						<option value={x} key={x}>
							{x}
						</option>
					)
				})}
			</CustomSelect>
			{stockQues}
			{structureQues}
			{otherSIQues}
		</>
	)
}

// consists of: PIN code, address, Floor no
const AddressAndStructureDetails = ({
	pincode,
	handlePincode,
	setAddress,
	floor,
	setFloor,
	city,
	setCity,
	state,
	setState,
}) => {
	const { getFloorValues } = useFetch()
	const floorValues = getFloorValues()

	const floorDropdown = floorValues.map(x => {
		return (
			<option value={x} key={x}>
				{x}
			</option>
		)
	})

	return (
		<>
			<Space y={1} />
			<p>Address details:</p>
			<Space y={1} />
			<CustomInput
				type="text"
				label="Please enter the pin code"
				placeholder="Eg: 400703"
				compulsory={true}
				value={pincode}
				onChange={e => handlePincode(e.target.value)}
			/>
			<Space y={1} />
			<CustomInput
				type="text"
				label="Address (First line & Landmark)"
				placeholder="Eg: 3rd Floor, Raheja Chambers, Opp Vidhan Sabha"
				compulsory={true}
				onChange={e => setAddress(e.target.value)}
			/>
			<Space y={1} />
			<Text style={textStyle}>
				Choose Floor No.
				<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
			</Text>
			<CustomSelect placeholder="Select" value={floor} onChange={e => setFloor(e)}>
				{floorDropdown}
			</CustomSelect>
			<Space y={1} />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<CustomInput
					type="text"
					label="City"
					placeholder="Enter here"
					compulsory={true}
					value={city}
					onChange={e => setCity(e.target.value)}
					style={{ width: '150px' }}
				/>
				<Space x={2} />
				<CustomInput
					type="text"
					label="State"
					placeholder="Enter here"
					compulsory={true}
					value={state}
					onChange={e => setState(e.target.value)}
					style={{ width: '150px' }}
				/>
			</div>
		</>
	)
}

const StockDetails = ({
	facility,
	business,
	setGoodsSold,
	setRawMaterials,
	setFinishedGoods,
	goodsSold,
	rawMaterials,
	finishedGoods,
	facilityUITitle,
}) => {
	const { goodsPlaceholder, rawMaterialsPlaceholder, finishedGoodsPlaceholder } =
		getStockPlaceholder(facility, business)

	useEffect(() => {
		setGoodsSold('')
		setRawMaterials('')
		setFinishedGoods('')
		// eslint-disable-next-line
	}, [facility])

	let stockQues = (
		<CustomInput
			type="text"
			label={`What goods are sold at the ${facilityUITitle}?`}
			placeholder={goodsPlaceholder}
			value={goodsSold}
			onChange={e => setGoodsSold(e.target.value)}
		/>
	)
	if (facility === 'Godown/Warehouse' || facility === 'Factory/Manufacturer') {
		stockQues = (
			<>
				<CustomInput
					type="text"
					label="What Raw Material (Kaccha Maal) are used?"
					placeholder={rawMaterialsPlaceholder}
					value={rawMaterials}
					onChange={e => setRawMaterials(e.target.value)}
				/>
				<Space y={1} />
				<CustomInput
					type="text"
					label="What Finished Goods (Pakka Maal) are made?"
					placeholder={finishedGoodsPlaceholder}
					value={finishedGoods}
					onChange={e => setFinishedGoods(e.target.value)}
				/>
			</>
		)
	} else if (facility === 'Office') {
		stockQues = null
	}
	return stockQues
}

const MaterialsDetails = ({
	wall,
	roof,
	handleWalls,
	handleRoof,
	isOtherWall,
	isOtherRoof,
	setOtherWall,
	setOtherRoof,
}) => {
	const { getMaterials } = useFetch()
	const materials = getMaterials()

	const materialsDropdown = materials.map(x => {
		return (
			<option value={x} key={x}>
				{x}
			</option>
		)
	})

	return (
		<>
			<Text style={textStyle}>
				What material are the walls made of?
				<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
			</Text>
			<CustomSelect placeholder="Select" value={wall} onChange={e => handleWalls(e)}>
				{materialsDropdown}
			</CustomSelect>
			{isOtherWall ? (
				<>
					<Space y={1} />
					<CustomInput
						type="text"
						label="Other Wall (Please Specify)"
						placeholder="Enter here"
						compulsory={true}
						onChange={e => setOtherWall(e.target.value)}
					/>
				</>
			) : null}

			<Space y={1} />
			<Text style={textStyle}>
				What material is the roof made of?
				<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
			</Text>
			<CustomSelect placeholder="Select" value={roof} onChange={e => handleRoof(e)}>
				{materialsDropdown}
			</CustomSelect>
			{isOtherRoof ? (
				<>
					<Space y={1} />
					<CustomInput
						type="text"
						label="Other Roof (Please Specify)"
						placeholder="Enter here"
						compulsory={true}
						onChange={e => setOtherRoof(e.target.value)}
					/>
				</>
			) : null}
		</>
	)
}

const FacilityDetails = ({
	hideNOBForFacilities,
	facility,
	handleFacility,
	isOtherFacility,
	setOtherFacility,
	business,
	handleBusiness,
	isOtherBusiness,
	setOtherBusiness,
	facilityData,
	facilityUITitle,
}) => {
	const [nobDropdown, setnobDropdown] = useState([])

	const { getFacilities } = useFetch()

	const facilityValues = getFacilities()

	const facilities = facilityValues.map(x => {
		return (
			<option value={x} key={x}>
				{x}
			</option>
		)
	})
	useEffect(() => {
		handleBusiness('')
		setOtherBusiness('')

		const facilitySubData = facilityData[facility]
		setnobDropdown(
			facilitySubData?.nobValues?.map(x => {
				return (
					<option value={x} key={x}>
						{x}
					</option>
				)
			})
		)
		// eslint-disable-next-line
	}, [facility])

	const natureOfBusinessComp = (
		<>
			<Space y={1} />
			<Text style={textStyle}>
				What kind of {facilityUITitle}?
				<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
			</Text>
			<CustomSelect placeholder="Select" value={business} onChange={e => handleBusiness(e)}>
				{nobDropdown}
			</CustomSelect>
		</>
	)

	return (
		<>
			<Space y={1} />
			<p>Business details:</p>
			<Space y={1} />
			<Text style={textStyle}>
				Type of Facility
				<span style={{ color: theme.colors.red, fontWeight: 700 }}> *</span>
			</Text>
			<CustomSelect placeholder="Select" value={facility} onChange={e => handleFacility(e)}>
				{facilities}
			</CustomSelect>
			{isOtherFacility ? (
				<>
					<Space y={1} />
					<CustomInput
						type="text"
						label="Other (Please Specify)"
						placeholder="Enter here"
						compulsory={true}
						onChange={e => setOtherFacility(e.target.value)}
					/>
				</>
			) : null}

			{hideNOBForFacilities.includes(facility) ? null : natureOfBusinessComp}
			<Space y={1} />
			{isOtherBusiness ? (
				<CustomInput
					type="text"
					label="Other (Please Specify)"
					placeholder="Enter here"
					compulsory={true}
					onChange={e => setOtherBusiness(e.target.value)}
				/>
			) : null}
		</>
	)
}

const CustomerDetails = ({
	setName,
	number,
	handlePhoneChange,
	validPhoneNumber,
	setCompanyName,
}) => {
	return (
		<>
			<Space y={2} />
			<p>Customer details:</p>
			<Space y={1} />
			<CustomInput
				type="text"
				label="Customer Name"
				placeholder="Enter here"
				compulsory={true}
				onChange={e => setName(e.target.value)}
			/>
			<Space y={1} />

			<CustomInput
				type="text"
				label="Company Name"
				placeholder="Enter here"
				compulsory={true}
				onChange={e => setCompanyName(e.target.value)}
			/>
			<Space y={1} />

			<InputGroup
				inputId="mobile"
				label="Customer Phone Number"
				isRequired={true}
				className="mb-0.5"
				style={{ width: '330px' }}
			/>
			<PhoneNoInput
				value={number}
				placeholder="Enter"
				onChange={handlePhoneChange}
				showAlert={!validPhoneNumber}
				alertText="Incorrect phone number"
				className="mb-2"
				style={{ width: '330px' }}
			/>
		</>
	)
}

const Container = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
`

const CustomInput = styled(Input)`
	width: 330px;
`

const CustomSelect = styled(Select)`
	width: 330px;
	height: 48px;
	background-color: white;
`

const textStyle = {
	marginBottom: '0.5rem',
	color: theme.colors.gray2,
	width: '330px',
}
