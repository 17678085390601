import { useContext, useRef } from 'react'
// import { postReq } from "./API";
import { Context } from '../context/Provider'
import { useAuth0 } from '@auth0/auth0-react'

const url = process.env.REACT_APP_SERVER_URL
const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE

export const useFetch = () => {
	const { getAccessTokenSilently } = useAuth0()
	const [state] = useContext(Context)
	const stateRef = useRef()
	stateRef.current = state

	const handleResponse = response => {
		if (!response.ok) {
		} else {
		}

		return response.json()
	}

	const sendRequest = async (endPoint, type = 'POST', body) => {
		const token = await getAccessTokenSilently({
			audience: auth0Audience,
		})

		const result = await fetch(`${url}/${endPoint}`, {
			method: type,
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json',
			},
			body: body ? JSON.stringify(body) : null,
		}).then(handleResponse)

		return result
	}

	const uploadReviewedData = async payload => {
		const result = await sendRequest(`sqs/fos/lead`, 'POST', { ...payload })
		return result
	}

	// should have api's
	const getFacilities = () => {
		const facilityValues = [
			'Shop',
			'Godown/Warehouse',
			'Office',
			'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen',
			'Factory/Manufacturer',
			'Motor vehicle showroom',
			'Motor vehicle garage',
			'Other',
		]
		return facilityValues
	}
	const hideNOBFor = () => {
		const facilityValues = [
			'Motor vehicle showroom',
			'Motor vehicle garage',
			'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen',
			'Other',
			'', // handles when there is no facility value initially
		]
		return facilityValues
	}
	const getFacilityData = () => {
		const values = {
			Shop: {
				nobValues: [
					'Retailing of FMCG/Grocery products',
					'Retailing of medical/pharmaceutical products',
					'Retailing of garments',
					'Retailing of electronic/electrical products',
					'Retailing of hardware products',
					'Retailing of jewellery',
					'Retailing of plastic products',
					'Retailing of chemical products/fertilizers',
					'Retailing of paints',
					'Retailing of Furniture',
					'Other',
				],
				stockQues: 'Value of goods in shop (e.g. ₹10,00,000)',
			},
			'Godown/Warehouse': {
				nobValues: [
					'Storage of FMCG/Grocery products',
					'Storage of garments',
					'Storage of furniture',
					'Storage of electronic/electrical products',
					'Storage of hardware products',
					'Other',
				],
				stockQues: 'Value of goods in godown (e.g. ₹10,00,000)',
			},
			Office: {
				nobValues: [
					'Real estate',
					'Consultancy',
					'Digital Marketing',
					'Travel Agency',
					'IT Services (Software)',
					'Legal Services',
					'Financial Service (Loan, mutual funds etc)',
					'Interior Designing Services',
					'Other',
				],
				stockQues: 'Value of goods (e.g. ₹10,00,000)',
			},
			'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen': {
				stockQues: 'Value of goods (e.g. ₹10,00,000)',
			},
			'Factory/Manufacturer': {
				nobValues: [
					'Garments',
					'Electronic/Electrical Products',
					'Furniture/Wooden Materials',
					'Spices',
					'Plastic Materials',
					'Hardware Products',
					'Rubber Products',
					'Paper Products',
					'Food Items',
					'Oil',
					'Other',
				],
				stockQues: 'Value of goods in factory (e.g. ₹20,00,000)',
			},
			'Motor vehicle showroom': {
				stockQues: 'Value of goods (e.g. cars/bikes - ₹10,00,000)',
			},
			'Motor vehicle garage': {
				stockQues: 'Value of goods (e.g. tyres/oil - ₹10,00,000)',
			},
			Other: {
				stockQues: 'Value of goods (e.g. ₹10,00,000)',
			},
		}
		return values
	}
	const getMaterials = () => {
		const result = [
			'Bamboo',
			'Bricks/Cement/Concrete',
			'Canvas',
			'Plastic',
			'Tin',
			'Tarpaulin',
			'Other',
		]
		return result
	}
	const getFloorValues = () => {
		const result = ['-1', '0', '1', '2', '3', '4', '5', 'Whole Building', 'Multiple Floors']
		return result
	}
	const getOwnershipValues = () => {
		const result = ['Owned', 'Rented']
		return result
	}

	return {
		sendRequest,
		uploadReviewedData,
		getFacilities,
		hideNOBFor,
		getFacilityData,
		getMaterials,
		getFloorValues,
		getOwnershipValues,
	}
}
