import React from 'react'
import { Switch, BrowserRouter, Route } from 'react-router-dom'

import Navbar from './components/Navbar'

import Home from './screens/Home'
import Success from './components/Success'

import ProtectedRoute from './auth/protected-route'

import { useAuth0 } from '@auth0/auth0-react'

function App() {
	const { isAuthenticated } = useAuth0()
	return (
		<BrowserRouter>
			<Navbar isAuthenticated={isAuthenticated} />
			<Switch>
				<Route path="/" exact={true} component={Home} />
				<Route path="/logout" exact={true} component={Home} />
				<ProtectedRoute path="/success" exact={true} component={Success} />
			</Switch>
		</BrowserRouter>
	)
}
export default App
