export const getStockPlaceholder = (facility, business) => {
	let goodsPlaceholder, rawMaterialsPlaceholder, finishedGoodsPlaceholder
	if (facility === 'Shop') {
		switch (business) {
			case 'Retailing of FMCG/Grocery products':
				goodsPlaceholder = 'Example: Dal, Oil, Sugar, Rice, Dairy Products'
				break
			case 'Retailing of medical/pharmaceutical products':
				goodsPlaceholder = 'Example: Medicines, Pharmaceutical Products, Ayurvedic Products'
				break
			case 'Retailing of garments':
				goodsPlaceholder = 'Example: Shirts, T-Shirts, Jeans, Sarees, Suits'
				break
			case 'Retailing of electronic/electrical products':
				goodsPlaceholder = 'Example: Mobile phones, Accessories, TV, Wires, Sockets'
				break
			case 'Retailing of hardware products':
				goodsPlaceholder = 'Example: Locks, Pipes & Fittings, Doors'
				break
			case 'Retailing of jewellery':
				goodsPlaceholder =
					'Example: Necklace, Rings, Earrings, Diamonds, Gold, Silver, Artificial Jewellery'
				break
			case 'Retailing of plastic products':
				goodsPlaceholder = 'Example: Bottles, Cups, Plates, Toys'
				break
			case 'Retailing of chemical products/fertilizers':
				goodsPlaceholder = 'Example: Epoxy, Fertilizers, Pesticides, Adhisives, Calcium Chloride'
				break
			case 'Retailing of paints':
				goodsPlaceholder = 'Example: Paints, Varnish, Powder Coating, POP, Gypsum'
				break
			case 'Retailing of Furniture':
				goodsPlaceholder = 'Example: Chairs, Beds, Tables'
				break
			default:
				goodsPlaceholder = 'Example: Garments, Fabrics, Accessories'
		}
	} else if (facility === 'Godown/Warehouse' || facility === 'Factory/Manufacturer') {
		switch (business) {
			case 'Storage of FMCG/Grocery products':
				rawMaterialsPlaceholder = 'Example: Vegetables, Cereals, Pulses'
				finishedGoodsPlaceholder = 'Example: Dal, Oil, Sugar, Rice, Dairy Products'
				break
			case 'Storage of garments':
			case 'Garments':
				rawMaterialsPlaceholder = 'Example: Cotton Cloth, Silk Cloth, Buttons'
				finishedGoodsPlaceholder = 'Example: Shirts, T-Shirts, Jeans, Sarees, Suits'
				break
			case 'Storage of furniture':
			case 'Furniture/Wooden Materials':
				rawMaterialsPlaceholder = 'Example: Plywood, Plastic, Iron, Glass'
				finishedGoodsPlaceholder = 'Example: Chairs, Beds, Tables'
				break
			case 'Storage of electronic/electrical products':
			case 'Electronic/Electrical Products':
				rawMaterialsPlaceholder = 'Example: Iron, Copper, Wires, Sockets'
				finishedGoodsPlaceholder =
					'Example: Mobile phones, Accessories, TV, Fridge, Washing Machine'
				break
			case 'Storage of hardware products':
			case 'Hardware Products':
				rawMaterialsPlaceholder = 'Example: Iron rods, Iron sheet, Plywood'
				finishedGoodsPlaceholder = 'Example: Locks, Pipes & Fittings, Doors'
				break
			case 'Plastic Materials':
				rawMaterialsPlaceholder = 'Example: PVC, Polycarbonate, Foam sheet, Foam sheet'
				finishedGoodsPlaceholder = 'Example: Bottles, Cups, Plates, Toys'
				break
			case 'Spices':
				rawMaterialsPlaceholder = 'Example: Spices, Grains, Cloves, Cardamom, Carom Seeds'
				finishedGoodsPlaceholder =
					'Example: Chilly Powder, Turmeric, Ginger Powder, Cardamom powder'
				break
			case 'Rubber Products':
				rawMaterialsPlaceholder = 'Example: Natural Rubber, Synthetic Rubber'
				finishedGoodsPlaceholder = 'Example: Tyres, Footwear, Tubes'
				break
			case 'Paper Products':
				rawMaterialsPlaceholder = 'Example: Paper, Brown Paper'
				finishedGoodsPlaceholder = 'Example: Books, Paper plates, Paper cups, tissue paper'
				break
			case 'Food Items':
				rawMaterialsPlaceholder = 'Example: Paper, Brown Paper'
				finishedGoodsPlaceholder = 'Example: Books, Paper plates, Paper cups, tissue paper'
				break
			case 'Oil':
				rawMaterialsPlaceholder = 'Example: Sesame, Mustard, Coconut, Soyabean, Sunflower'
				finishedGoodsPlaceholder = 'Example: Cooking oil, Hair oil'
				break
			default:
				rawMaterialsPlaceholder = 'Enter here'
				finishedGoodsPlaceholder = 'Enter here'
		}
	} else if (facility === 'Motor vehicle garage') {
		goodsPlaceholder = 'Example: Screws, Nuts, Bolts, Cables, Batteries, Ball bearings'
	} else if (facility === 'Motor vehicle showroom') {
		goodsPlaceholder = 'Example: Cars, Scooters, Motorcycles, Electric vehicles'
	} else if (facility === 'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen') {
		goodsPlaceholder = 'Example: Refrigerator, Oven, Grill Machine, Deep Freezer'
	} else {
		goodsPlaceholder = 'Enter here'
	}

	return { goodsPlaceholder, rawMaterialsPlaceholder, finishedGoodsPlaceholder }
}
