export const getTypeOfFacility = email => {
	const CHANNEL_PARTNERS = {
		IADV: '',
		PRSN: '',
		LFIN: '',
		SUPK: 'Shop',
		BSEVA: '',
		PTECH: 'Factory/Manufacturer',
	}

	const partner = email.split('_').pop().split('@')[0] // IADV
	if (Object.keys(CHANNEL_PARTNERS).includes(partner)) {
		return CHANNEL_PARTNERS[partner]
	}
	return ''
}
