import { Navbar, NavDropdown, Container } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react'

import { theme, Text } from 'verak-ui'

import Logo from '../assets/logomark-orange.svg'

const navStyles = {
	height: '64px',
	boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
	background: theme.colors.primary,
}

export default function NavigationBar({ isAuthenticated }) {
	const { user, logout } = useAuth0()

	let executiveName = user?.name || 'Executive Name'

	const handleLogout = () => {
		logout({
			returnTo: `${window.location.origin}/logout`,
		})
	}

	return (
		<Navbar className="custom-navbar" style={navStyles}>
			<Container fluid id="navbarContainer">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<img src={Logo} alt="Verak" style={{ paddingLeft: '1rem' }} />
						<Text style={{ paddingLeft: '0.75rem', color: 'white', fontWeight: 600 }}>
							VERAK FOS Platform
						</Text>
					</div>

					{isAuthenticated && (
						<Navbar.Collapse id="basic-navbar-nav">
							<Navbar.Collapse className="flex-container">
								<NavDropdown
									style={{
										color: 'white !important',
										fontSize: '14px',
										fontWeight: '400',
										marginRight: '1rem',
									}}
									title={executiveName}
									id="basic-nav-dropdown"
								>
									<NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
								</NavDropdown>
							</Navbar.Collapse>
						</Navbar.Collapse>
					)}
				</div>
			</Container>
		</Navbar>
	)
}
