import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { Redirect } from 'react-router'
import { Container } from 'react-bootstrap'
import { Button } from 'verak-ui'

import LeadInfo from './LeadInfo'
import { Footer } from '../components/Footer'

const Home = () => {
	const { isLoading, isAuthenticated, loginWithPopup } = useAuth0()
	const history = useHistory()
	if (isLoading) {
		return (
			<>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						marginTop: '3rem',
					}}
				>
					Loading...
					<Footer />
				</div>
			</>
		)
	}

	if (!isAuthenticated) {
		return (
			<Container
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '3rem',
				}}
			>
				<Button onClick={loginWithPopup} label="Login to continue" />
				<Footer />
			</Container>
		)
	} else {
		if (history.location.pathname === '/logout') {
			return <Redirect to="/" />
		}
		return <LeadInfo />
	}
}

export default Home
